/* eslint-disable @typescript-eslint/no-unused-vars */

export interface SubMenuItem {
  name: string
  slug: string
}

interface MenuItemWithCopy {
  name: string
  slug: '/about' | '/approach' | '/insights' | '/join' // exactly typed to help with NextJS Link route checking
  copy: string
}

export interface MenuItemWithChildren {
  name: string
  slug: '/services' | '/industries' // exactly typed to help with NextJS Link route checking
  items?: SubMenuItem[]
}

type MenuItem = MenuItemWithCopy | MenuItemWithChildren

const servicesItems: SubMenuItem[] = [
  { name: 'Strategy & Advisory', slug: 'strategy-and-advisory' },
  { name: 'Data Analytics & Insights', slug: 'data-analytics-and-insights' },
  { name: 'Artificial Intelligence', slug: 'artificial-intelligence' },
  { name: 'Product', slug: 'product' },
  {
    name: 'Data & Application Modernisation',
    slug: 'data-and-app-modernisation',
  },
  { name: 'Intelligent Platforms', slug: 'intelligent-platforms' },
]
const services: MenuItemWithChildren = {
  name: 'Services',
  slug: '/services',
  items: servicesItems,
}

const industriesItems: SubMenuItem[] = [
  { name: 'Financial Services', slug: 'financial-services' },
  { name: 'Energy & Utilities', slug: 'energy-utilities' },
  { name: 'Retail', slug: 'retail' },
]
const industries: MenuItemWithChildren = {
  name: 'Industries',
  slug: '/industries',
  // items: industriesItems,
}

const about: MenuItemWithCopy = {
  name: 'About',
  slug: '/about',
  copy: "We're one of the fastest growing consultancies in APAC",
}
const approach: MenuItemWithCopy = {
  name: 'Approach',
  slug: '/approach',
  copy: 'Find out what makes V2 AI different',
}
const insights: MenuItemWithCopy = {
  name: 'Insights',
  slug: '/insights',
  copy: 'Explore expert digital industry trends.',
}
const join: MenuItemWithCopy = {
  name: 'Join',
  slug: '/join',
  copy: 'Ready to make your mark?',
}

export const headerMenu: MenuItem[] = [
  about,
  services,
  industries,
  insights,
  join,
]

export const footerLeft: MenuItemWithCopy[] = [about, insights, join]
export const footerRight: MenuItemWithChildren[] = [services, industries]

export const menuItemIsWithChildren = (
  item: MenuItem,
): item is MenuItemWithChildren => 'items' in item
