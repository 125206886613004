'use client'

import { ForwardButton, ForwardLink } from '@components/interaction/button'
import HomeLink from './home-link'
import styles from './header.module.css'
import { useHeader } from './header-provider'
import { NavigationMenu, Sub } from '@radix-ui/react-navigation-menu'
import {
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@components/ui/navigation-menu'
import Link from 'next/link'
import { cn } from '@lib/utils'
import {
  type FC,
  type CSSProperties,
  type MouseEventHandler,
  type PointerEventHandler,
  type TouchEventHandler,
  useState,
  useMemo,
  useCallback,
} from 'react'
import {
  headerMenu,
  menuItemIsWithChildren,
  type MenuItemWithChildren,
} from './navigation'
import { useWindowSize } from '@components/hooks/use-window-size'

interface SubMenuThemeColors {
  text: string
  border: string
  bg: string
}

const themeColors: Record<'light' | 'dark', SubMenuThemeColors> = {
  light: {
    text: 'text-black',
    border: 'border-black',
    bg: 'bg-white',
  },
  dark: {
    text: 'text-white',
    border: 'border-white',
    bg: 'bg-black',
  },
}

export default function Header() {
  const { width } = useWindowSize()
  const { setOpen, isOpen, isDark } = useHeader()
  const [navMenuValue, setNavMenuValue] = useState<string | undefined>()
  const subMenuOpen = useMemo(
    () => navMenuValue != null && navMenuValue !== '',
    [navMenuValue],
  )
  const theme = isDark ? 'dark' : 'light'
  const colors = themeColors[theme]

  const closeMenu = () => {
    const menuBtn = document.querySelector<HTMLInputElement>('#menu-btn')
    if (menuBtn != null) {
      menuBtn.checked = false
    }
    setOpen(false)
  }

  const cancelHoverEvent: PointerEventHandler<HTMLElement> = (event) => {
    if (width != null && width < 1024) {
      event.preventDefault()
    }
  }

  /**
   * We currently render a link inside the sub-menu trigger (button) element - aka. <button value="/services"><a href="/services">...</a></button>
   * We need to prevent going to the link when click in certain circumstances where opening the sub menu is the priority.
   * eg. we're showing the "mobile" side menu (also just shows on smaller desktop windows)
   * eg2. (NOT DONE) large touch devices - tablets - which cannot hover the button to show the sub menu
   */
  const onClickLinkInsideTrigger: MouseEventHandler<HTMLAnchorElement> &
    TouchEventHandler<HTMLAnchorElement> &
    PointerEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      if (width != null && width < 1024) {
        event.preventDefault()
        event.currentTarget.parentElement?.click()
        return
      }

      // attempt 1 at detecting touch events
      // failing on some devices as iOS tells us `pointerType: 'mouse'` instead, despite being an iPhone
      // working on iOS Safari with HACK - pointerId < 0
      // NOT working on iOS Chrome - gives a regular positive ID
      if ('pointerType' in event.nativeEvent) {
        const { pointerType, pointerId } = event.nativeEvent
        const touchPointerType =
          pointerType === 'touch' || pointerType === 'pen'
        const isSafariIOS = pointerType === 'mouse' && pointerId < 0
        if (pointerType !== undefined && (touchPointerType || isSafariIOS)) {
          event.preventDefault()
          event.stopPropagation()
          event.currentTarget.parentElement?.click()
          return
        }
      }

      // ELSE we went to the link as usual and need to close the menu
      closeMenu()
    },
    [width],
  )

  const subMenuBack = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setNavMenuValue(undefined)
  }

  const onValueChange = (value: string) => {
    setNavMenuValue(value)
  }

  return (
    <header
      className={
        styles.header +
        ` relative z-50 w-screen justify-center bg-transparent py-5 text-base lg:flex`
      }
    >
      <div className='max-width flex lg:items-center'>
        <div className={`${styles['top-container']} flex justify-between`}>
          <HomeLink />
          <div className='flex-1' />
          <input
            className={`${styles.menuBtn} hidden h-10 appearance-none hover:bg-neutral-900 `}
            type='checkbox'
            id='menu-btn'
            onChange={(event) => {
              setOpen(event.target.checked)
            }}
          />
          <label
            className={`${styles.menuIcon} relative  inline-block select-none px-5 py-7 lg:hidden`}
            data-testid='menu-open'
            htmlFor='menu-btn'
          >
            <span
              className={`${styles.navicon} transition-all duration-300 ${
                isDark && !isOpen
                  ? 'bg-white before:bg-white after:bg-white'
                  : 'bg-black before:bg-black after:bg-black'
              }`}
            />
          </label>
        </div>
        <NavigationMenu
          value={navMenuValue}
          onValueChange={onValueChange}
          className={cn(styles.menu, 'flex-grow')}
        >
          <NavigationMenuList
            id='navmenu'
            className={cn(
              {
                [styles.navMenuListSubMenuOpen]: subMenuOpen,
              },
              'relative flex h-screen w-full list-none flex-col justify-start gap-0 text-zinc-900 transition-transform duration-300 lg:h-full lg:flex-row lg:justify-center lg:gap-7 [&]:text-lg',
              'space-y-0 lg:space-y-0',
              '!space-x-0 lg:space-x-1',
              'items-start lg:items-center',
              'px-[5vw] py-4 lg:p-0',
            )}
          >
            {headerMenu.map((item) => {
              return menuItemIsWithChildren(item) && item.items != null ? (
                <NavigationMenuItem
                  value={item.slug}
                  key={item.slug}
                  style={
                    {
                      '--accent-foreground': isDark ? 'white' : 'black',
                    } as CSSProperties
                  }
                  className={cn(
                    colors.text,
                    'text-center max-lg:text-black lg:text-start',
                  )}
                >
                  <NavigationMenuTrigger
                    value={item.slug}
                    onPointerOver={cancelHoverEvent}
                    onPointerEnter={cancelHoverEvent}
                    onPointerMove={cancelHoverEvent}
                    className={cn(
                      styles.navMenuTrigger,
                      colors.text,
                      'relative uppercase max-lg:text-black [&]:text-lg',
                      'px-0',
                      'py-3 lg:py-2',
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      'after:bg-black lg:after:bg-[var(--accent-foreground)]',
                      // eslint-disable-next-line sonarjs/no-duplicate-string
                      '!transition-none',
                    )}
                  >
                    <NavigationMenuLink asChild>
                      <Link
                        href={item.slug}
                        onClick={onClickLinkInsideTrigger}
                        className={cn(
                          styles.navMenuLinkInsideTrigger,
                          navigationMenuTriggerStyle(),
                          'relative uppercase [&]:text-lg',
                          'px-0',
                          'py-3 lg:px-2',
                          'after:bg-black lg:after:bg-[var(--accent-foreground)]',
                          '!transition-none',
                        )}
                      >
                        {item.name}
                      </Link>
                    </NavigationMenuLink>
                  </NavigationMenuTrigger>
                  <NavigationMenuContentSubMenu
                    item={item}
                    colors={colors}
                    sideMenuOpen={isOpen}
                    onClick={closeMenu}
                    onClickBack={subMenuBack}
                    onPointerEvent={cancelHoverEvent}
                  />
                </NavigationMenuItem>
              ) : (
                <NavigationMenuItem
                  key={item.slug}
                  style={
                    {
                      '--accent-foreground': isDark ? 'white' : 'black',
                    } as CSSProperties
                  }
                  className={cn(colors.text, 'max-lg:text-black')}
                >
                  <NavigationMenuLink onClick={closeMenu} asChild>
                    <Link
                      href={item.slug}
                      className={cn(
                        styles.navMenuLink,
                        navigationMenuTriggerStyle(),
                        'relative uppercase [&]:text-lg',
                        'px-0',
                        'py-3 lg:px-2',
                        'after:bg-black lg:after:bg-[var(--accent-foreground)]',
                        '!transition-none',
                      )}
                    >
                      {item.name}
                    </Link>
                  </NavigationMenuLink>
                </NavigationMenuItem>
              )
            })}

            <li className='hidden flex-1 lg:block' />
            <NavigationMenuItem>
              <NavigationMenuLink asChild>
                <ForwardLink
                  className={cn(
                    styles.contactButton,
                    isDark ? 'lg:border-white lg:text-white' : '',
                  )}
                  variant='outlined'
                  colour={
                    isDark && !isOpen ? 'secondary-contrast' : 'secondary'
                  }
                  onClick={closeMenu}
                  href='/contact'
                  aria-label='Contact'
                  size='sm'
                  data-testid='header-contact-button'
                >
                  CONTACT
                </ForwardLink>
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </header>
  )
}

const NavigationMenuContentSubMenu: FC<{
  item: MenuItemWithChildren
  colors: SubMenuThemeColors
  sideMenuOpen: boolean
  onClick: MouseEventHandler<HTMLAnchorElement>
  onClickBack: () => void
  onPointerEvent: PointerEventHandler<HTMLElement>
}> = ({ item, colors, sideMenuOpen, onClick, onClickBack, onPointerEvent }) => {
  const { items = [], name, slug: urlRoot } = item
  return (
    <NavigationMenuContent
      forceMount={true}
      id={`navmenucontent-${urlRoot}`}
      className={cn(
        styles.navContent,
        colors.border,
        colors.bg,
        colors.text,
        'opacity-0 transition-opacity',
        'text-left max-lg:border-black max-lg:bg-white max-lg:text-black',
        'lg:border-[1px]',
        'text-sm lg:text-lg',
        'absolute left-[calc(50%+50vw)] z-10 lg:left-auto lg:top-[120%]',
        'w-[100vw] md:w-[100vw] lg:w-auto',
        'px-[5vw] py-4 lg:p-5',
        'lg:mb-0',
      )}
      onPointerOut={onPointerEvent}
      onPointerLeave={onPointerEvent}
    >
      <Sub orientation='vertical'>
        <NavigationMenuList className='flex-col items-start justify-start gap-0 font-medium'>
          <NavigationMenuItem className='md:whitespace-nowrap lg:hidden'>
            <NavigationMenuLink asChild>
              <ForwardButton
                size='md'
                variant='text'
                onClick={onClickBack}
                colour='secondary'
                className={cn(
                  styles.navMenuLink,
                  navigationMenuTriggerStyle(),
                  'mb-3 [&]:px-0 [&]:py-1 [&]:text-base [&]:font-medium',
                  '!after:bg-black',
                  '!transition-none',
                )}
              >
                &lt; Back
              </ForwardButton>
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem className='md:whitespace-nowrap'>
            <NavigationMenuLink onClick={onClick} asChild>
              <Link
                href={urlRoot}
                className={cn(
                  styles.navMenuLink,
                  navigationMenuTriggerStyle(),
                  'relative px-0 text-lg uppercase',
                  'after:bg-black lg:after:bg-[var(--accent-foreground)]',
                  '!transition-none',
                )}
              >
                All {name}
              </Link>
            </NavigationMenuLink>
          </NavigationMenuItem>
          <li className='mb-3 mt-2 w-full [border-top:1px_solid_black] lg:hidden' />

          {items.map((subMenuItem) => (
            <NavigationMenuItem
              key={subMenuItem.slug}
              className='md:whitespace-nowrap lg:pl-2'
            >
              <NavigationMenuLink asChild onClick={onClick}>
                <Link
                  href={`${urlRoot}/${subMenuItem.slug}`}
                  className={cn(
                    styles.navMenuLink,
                    navigationMenuTriggerStyle(),
                    'relative px-0 uppercase',
                    'after:bg-black lg:after:bg-[var(--accent-foreground)]',
                    'h-auto min-h-10 w-auto lg:w-max',
                    '!transition-none',
                  )}
                >
                  {subMenuItem.name}
                </Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
          ))}

          <li className='mb-3 mt-2 w-full [border-top:1px_solid_black] lg:hidden' />
        </NavigationMenuList>
      </Sub>
    </NavigationMenuContent>
  )
}
